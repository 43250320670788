<template>
<loader v-bind="{ loading }" text="Loading">
  <form>
    <columns>
      <column>
        <text-input
          required
          description="The interally referenced name for this team."
          classes="is-medium is-rounded"
          :error="$root.errors.name"
          :value="team.name"
          @input="updateName">Name</text-input>
      </column>
      <column>
        <data-selector
          required
          description="The branch under which this team works."
          classes="is-medium is-rounded"
          :items="branch_list"
          value-key="uuid"
          label-key="name"
          :error="$root.errors.branch_uuid"
          :value="team.branch_uuid"
          @input="branchWasSelected">
          Branch
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <data-selector
          required
          classes="is-medium is-rounded"
          searchable
          description="The staff member responsible for leading the team."
          :items="branch_user_list"
          value-key="uuid"
          label-key="full_name"
          :error="$root.errors.team_lead_uuid"
          :value="team.team_lead_uuid"
          @input="setTeamLead">Team Lead</data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="updating"
          :disabled="deleting"
          @submit="updateTeam">Save</submit-button>
      </column>
      <column class="is-narrow" v-if="team.work_orders_count === 0">
        <action-button
          class="is-small is-rounded is-danger"
          :working="deleting"
          :disabled="updating"
          @click="deleteTeam">Delete</action-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { branch as backend } from '@/api'
import { findIndex } from 'lodash'

export default {

  data: () => ({
    loading: true,
    updating: false,
    deleting: false,
    branch_user_list: []
  }),

  async beforeCreate() {
    await this.$store.dispatch('branch/loadList')
  },

  mounted() {
    this.branch_user_list = this.team.branch.users
    this.loading = false
  },

  methods: {
    ...mapMutations('team', [
      'updateName',
      'updateBranch',
      'updateTeamLead',
      'clearTeam'
    ]),
    setTeamLead(userId) {
      if (userId && !this.teamLeadIsAlreadyAMember(userId)) {
        this.updateMembers(this.team.users.push(this.branch_user_list.find(user => user.uuid === userId)))
      }
      this.updateTeamLead(userId)
    },
    teamLeadIsAlreadyAMember(userId) {
      return findIndex(this.team.users, user => user.uuid === userId) > -1
    },
    branchWasSelected(branchId) {
      this.$store.commit('team/clearMembers')
      this.$store.commit('team/updateTeamLead', '')
      this.$store.commit('team/updateTeamLeadId', '')
      this.updateBranch(branchId)
      if (branchId) {
        return backend.loadUserList(branchId, ({data}) => {
          this.branch_user_list = data
        }, () => {})
      }
    },
    updateTeam() {
      this.updating = true
      this.$store.dispatch('team/update').then(() => {
        this.$toast.success('Team successfully updated')
        this.updating = false
      }).catch(() => this.updating = false)
    },
    async deleteTeam() {
      if(await this.$confirm({
        title: 'Delete Team',
        message: 'Are you sure you want to delete this team?'
      })) {
        this.deleting = true
        this.$store.dispatch('team/deleteTeam', this.team.uuid).then(() => {
          this.$toast.success('Team successfully deleted')
          this.$router.push({
            name: 'teams'
          })
          this.deleting = false
        }).catch(() => {
          this.$toast.error('Unable to delete team, please try again.')
          this.deleting = false
        })
      }
    }
  },

  computed: {
    ...mapGetters('team', [
      'team'
    ]),
    ...mapGetters('branch', [
      'branch_list'
    ])
  }

}
</script>